<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="10">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <span>Схема</span>
                <v-switch
                  v-model="$vuetify.theme.dark"
                  primary
                  label="Темная"
                />
              </v-col>
              <!--v-col cols="12" md="6">
                <span>Drawer</span>
                <v-radio-group v-model="primaryDrawer.type" column>
                  <v-radio
                    v-for="drawer in drawers"
                    :key="drawer"
                    :label="drawer"
                    :value="drawer.toLowerCase()"
                    primary
                  />
                </v-radio-group>
                <v-switch
                  v-model="primaryDrawer.clipped"
                  label="Clipped"
                  primary
                />
                <v-switch
                  v-model="primaryDrawer.floating"
                  label="Floating"
                  primary
                />
                <v-switch v-model="primaryDrawer.mini" label="Mini" primary />
              </v-col>
              <v-col cols="12" md="6">
                <span>Footer</span>
                <v-switch v-model="footer.inset" label="Inset" primary />
              </v-col-->
            </v-row>
          </v-card-text>
          <!--v-card-actions>
            <v-spacer />
            <v-btn text>Cancel</v-btn>
            <v-btn text color="primary">Submit!</v-btn>
          </v-card-actions-->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  methods: {},
  mounted() {
    // eslint-disable-next-line no-console
    console.log(this.$parent);
    // eslint-disable-next-line no-console
    console.log(this.primaryDrawer);
  }
};
</script>
